import * as React from 'react';
import { classes } from './SingleWidgetTile.st.css';
import { OfferingContextProvider } from '../catalog-offering-context';
import OfferingPrimaryInfo from '../Offering/OfferingPrimaryInfo/OfferingPrimaryInfo';
import {
  OfferingViewModelFactory,
  TileLayout,
} from '../../domain/offering-view-model-factory';
import OfferingImage from '../Offering/offering-image/offering-image';
import {
  ImageDisplayType,
  OfferingDisplayOptions,
  TextAlignmentType,
} from '../../business-logic/offering-display-options';
import { OfferingDomain, OfferingType } from '../../domain/offering-domain';
import { UserMessage } from '@wix/bookings-viewer-ui/user-message';
import { OfferingIntent } from '../../constants';
import { DeviceType } from '../../widget-viewer-main';
import { InjectedTranslateProps, translate } from 'react-i18next';

export interface WixEnvironment {
  deviceType: DeviceType;
  isEditorMode: boolean;
}

interface AppProps extends InjectedTranslateProps {
  offeringDomain: OfferingDomain;
  offeringDisplayOptions: OfferingDisplayOptions;
  onAction: Function;
  environment: WixEnvironment;
  onLoaded: Function;
  onLoadedStep: Function;
  dimensions: {
    height: number;
    width: number;
  };
  bookingPossible: boolean;
  bookItFailureCallback: Function;
  sendViewerOpenedEventCallback: Function;
  canReportLoading: boolean;
}

interface AppState {
  showErrorMessage: boolean;
}

export const isDummyService = (serviceType) => {
  return serviceType === OfferingType.DUMMY;
};

class SingleWidgetTile extends React.PureComponent<AppProps, AppState> {
  private isComonentLoaded = false;
  private isExecuteOnLoadedCallbacksRequired = true;

  constructor(props: AppProps) {
    super(props);
    this.state = { showErrorMessage: false };
  }

  hideErrorMessage() {
    this.setState({
      showErrorMessage: false,
    });
  }

  componentDidMount() {
    this.isComonentLoaded = true;
    this.executeOnLoadedCallbacks();
  }

  componentDidUpdate() {
    this.executeOnLoadedCallbacks();
  }

  // since the viewer might render the component when
  executeOnLoadedCallbacks() {
    if (
      this.isExecuteOnLoadedCallbacksRequired &&
      this.isComonentLoaded &&
      this.props.canReportLoading
    ) {
      this.props.sendViewerOpenedEventCallback();
      this.props.onLoaded();
      this.props.onLoadedStep();
      this.isExecuteOnLoadedCallbacksRequired = false;
    }
  }

  render() {
    const {
      offeringDomain,
      t,
      offeringDisplayOptions,
      environment,
      dimensions,
      bookingPossible,
    } = this.props;
    const isMobile = environment.deviceType === DeviceType.MOBILE;
    const tileLayout = isMobile ? TileLayout.VERTICAL : TileLayout.HORIZONTAL;
    const offeringViewModel = OfferingViewModelFactory.createOfferingViewModel(
      offeringDomain,
      offeringDisplayOptions,
      t,
      tileLayout,
    );

    const context = {
      offeringViewModel,
      deviceType: environment.deviceType,
    };

    const reversed =
      offeringDisplayOptions.getLayout.imageType === ImageDisplayType.NONE &&
      offeringDisplayOptions.getLayout.textAlignment !==
        TextAlignmentType.RIGHT;
    const shouldRenderImageContainer = this.shouldRenderImageContainer(
      context,
      environment,
    );
    const actionInDescription = this.shouldShowActionButtonInImageContainer(
      context,
      environment,
    );
    return (
      <OfferingContextProvider value={context}>
        {!environment.isEditorMode ? (
          <UserMessage
            isOpen={this.state.showErrorMessage}
            onRequestClose={() => this.hideErrorMessage()}
            isMobile={isMobile}
            message={offeringViewModel.errorMessageTranslated}
            okLabel={offeringViewModel.userMessageOkLabel}
          />
        ) : null}
        <div
          data-hook="widget-tile"
          className={`
            ${classes.root}
            ${classes[tileLayout]}
            ${classes[environment.deviceType] || ''}
            ${classes[offeringDisplayOptions.getLayout.imageType] || ''}
            ${
              shouldRenderImageContainer ? classes.dualView : classes.singleView
            }
            ${reversed ? classes.reversed : ''}`}
        >
          {shouldRenderImageContainer ? (
            <div
              className={`${reversed ? classes.right : classes.left} ${
                classes.tilePart
              }`}
              data-hook="tile-image-container"
            >
              <OfferingImage
                dimensions={dimensions}
                environment={environment}
                onAction={(intent, biReferral) =>
                  this.actionClicked(
                    intent,
                    context,
                    biReferral,
                    bookingPossible,
                  )
                }
                shouldShowActionButton={actionInDescription}
              />
            </div>
          ) : null}
          <div
            className={classes.tilePart}
            tabIndex={0}
            data-hook="tile-main-info"
          >
            <OfferingPrimaryInfo
              onAction={(intent, biReferral) =>
                this.actionClicked(intent, context, biReferral, bookingPossible)
              }
              isEditorMode={environment.isEditorMode}
              shouldShowActionButton={!actionInDescription}
              shouldShowDescriptionText={this.shouldShowDescriptionInPrimaryInfo(
                context,
                environment,
              )}
            />
          </div>
        </div>
      </OfferingContextProvider>
    );
  }

  private shouldRenderImageContainer(context, environment) {
    return (
      environment.deviceType === DeviceType.DESKTOP ||
      context.offeringViewModel.image.type !== ImageDisplayType.NONE
    );
  }

  private shouldShowActionButtonInImageContainer(context, environment) {
    return (
      context.offeringViewModel.image.type === ImageDisplayType.NONE &&
      environment.deviceType === DeviceType.DESKTOP
    );
  }

  private shouldShowDescriptionInPrimaryInfo(context, environment) {
    return (
      context.offeringViewModel.image.type === ImageDisplayType.NONE &&
      environment.deviceType === DeviceType.MOBILE
    );
  }

  private actionClicked(intent, context, biReferral, bookingPossible: boolean) {
    if (isDummyService(context.offeringViewModel.type)) {
      return;
    }
    if (intent === OfferingIntent.SHOW_DETAILS || bookingPossible) {
      this.props.onAction(intent, context.offeringViewModel.id, biReferral);
    } else {
      this.setState({ showErrorMessage: true });
      this.props.bookItFailureCallback(biReferral);
    }
  }
}

export default translate()(SingleWidgetTile);
