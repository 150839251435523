(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "react", "reactDOM"], factory);
	else if(typeof exports === 'object')
		exports["component"] = factory(require("lodash"), require("react"), require("react-dom"));
	else
		root["component"] = factory(root["_"], root["React"], root["ReactDOM"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__5210__, __WEBPACK_EXTERNAL_MODULE__1195__, __WEBPACK_EXTERNAL_MODULE__3596__) {
return 